@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

:root {
  --primary-color: #824575;
  --secondary-color: #fbd4c8;
  --tertiary-color: #faf0e4;
  --box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.container {
  margin: 90px auto 60px;
  max-width: 1200px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Bree Serif", serif;
  text-align: center;
  color: var(--primary-color);
}

h1 {
  font-size: 90px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 60px;
}

h4 {
  font-size: 35px;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.8;
  opacity: 0.8;
  text-align: center;
  letter-spacing: 1.5px;
}

.jana-image {
  max-width: 450px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.shecodes-link {
  color: var(--primary-color);
  text-decoration: none;
}

.shecodes-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.section {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 10px 5px;
}

img {
  display: block;
  margin: 0 auto;
}

.basics-image,
.plus-image,
.responsive-image,
.react-image {
  display: block;
  margin: 0 auto;
  max-width: 235px;
}

.architecture-link,
.weather-link,
.restaurant-link,
.react-weather-link,
.react-dictionary-link {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
  background-color: var(--secondary-color);
  padding: 15px;
  margin-top: 15px;
}

.architecture-link:hover,
.weather-link:hover,
.restaurant-link:hover,
.react-weather-link:hover,
.react-dictionary-link:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.email-link {
  color: var(--primary-color);
  font-size: 26px;
  font-weight: 500;
  text-decoration: none;
}

.email-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.social-links a {
  margin: 0 20px;
  color: var(--primary-color);
  background: var(--secondary-color);
  padding: 12px 18px;
  border-radius: 50%;
  font-size: 22px;
  transition: all 200ms ease-in-out;
}

.social-links a:hover {
  color: var(--secondary-color);
  background: var(--primary-color);
}

.coded-by {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.8;
  opacity: 0.8;
  text-align: center;
  letter-spacing: 1.5px;
}

.coded-by a {
  color: var(--primary-color);
  text-decoration: none;
}

.coded-by a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 40px;
  }

  h4 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }

  .email-link {
    font-size: 20px;
  }
}
